<template>
  <div class="applyprojForm">
    <PageNav title="项目资料填写" />
    <div class="form-wrapper">
      <van-form @submit="onSubmit" validate-trigger="onSubmit">
        <van-field
          v-model="formData.projectName"
          name="projectName"
          label="项目名称"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写项目名称' }]"
        />
        <van-field
          v-model="formData.projectAddress"
          name="projectAddress"
          label="项目地址"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写项目地址' }]"
        />
        <van-field name="projectType" label="项目类型">
          <template #input>
            <van-radio-group direction="horizontal" v-model="formData.projectType">
              <van-radio name="1">贷款</van-radio>
              <van-radio name="2">租赁</van-radio>
              <van-radio name="3">其它</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="formData.requireDesc"
          name="requireDesc"
          label="需求描述"
          rows="3"
          autosize
          type="textarea"
          maxlength="500"
          placeholder="请填写需求描述，最多500个字"
          show-word-limit
          :rules="[{ required: true, message: '请填写需求描述' }]"
        />
        <van-field
          v-model="formData.linkman"
          name="linkman"
          label="联系人"
          placeholder="请输入"
          :rules="[{ required: true, message: '请填写联系人' }]"
        />
        <van-field
          v-model="formData.linkPhone"
          name="linkPhone"
          label="联系电话"
          placeholder="请输入"
          type="tel"
          :rules="[{ required: true, message: '请填写联系电话' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Field, Form, RadioGroup, Radio, Uploader, Toast, Dialog } from 'vant';
import Fetch from '../common/fetch';
import { ApplyProject } from '../common/interface';

import PageNav from '@/components/PageNav';

Vue.use(Field);
Vue.use(Form);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Uploader);

export default {
  name: "ApplyprojForm",
  components: { PageNav },
  data() {
    return {
      formData: {
        projectName: '',
        projectAddress: '',
        projectType: '',
        requireDesc: '',
        linkman: '',
        linkPhone: '',
      },
      uploadList: [],
      logoUrlKey: '',
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    onSubmit(values) {
      Fetch.post(ApplyProject, values).then(() => {
        Dialog.alert({
          title: '提交成功',
          message: '项目提交成功,我们会尽快与您联系~',
        }).then(() => {
          this.$router.go(-1);
        });
      }).catch((err) => {
        Toast(err.msg || '请求失败');
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  .form-wrapper {
    position: relative;
    padding: 10px 5px 20px;
    background: #fff;
  }
</style>